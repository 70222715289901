
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'DevelopmentLicense',
    data() {
      return {
        ovalContent: [
          {
            title: '會議記錄',
            contents: [
              {
                title: '專案審議小組',
                to: '/development-license/meeting-minutes',
              },
              { title: '專案小組', to: '' },
            ],
          },
          {
            title: '委員名冊',
            contents: [{ title: '專案審議小組', to: '' }],
          },
        ],
      }
    },
  })
